$ const { get } = require('@ebay/retriever');

$ const {
    model: {
        radiusSearchForm: {
            form : {
                entries,
                validations
            } = {},
            cta 
        } = {}
    } = {}
} = input

$ const {
    invalidRadius,
    radiusValue,
    postalCodeValue
} = state || {};

$ let postalModel = get(entries, '[1]', {});
$ postalModel.validations = validations;
$ const radiusOptions = get(entries, '[0].entries', []);
$ const radiusLabel =  get(entries, '[0].label.textSpans[0].text', '');
$ const action = get(cta, "sections[0].dataItems[0].action", {});
$ const actionAccessibilityText = get(cta, "sections[0].dataItems[0].accessibilityText", '');

<div class="find-installer">
    <div class="find-installer__radius-dropdown">
        <ebay-select name="radius"
            floating-label=radiusLabel
            aria-invalid=(invalidRadius && 'true')
            on-change('handleValueEvent', 'value-change')>
            <for|option| of=(radiusOptions || [])>
                <@option
                    selected=(radiusValue == option.paramValue)
                    value=option.paramValue
                    text=get(option, 'label.textSpans[0].text', '') />
            </for>
        </ebay-select>
    </div>

    <div class="find-installer__postalCode">
        <postal-code
            model=postalModel
            value=postalCodeValue
            on-value-change('emit', 'value-change', action)
            on-disable-action('disableAction') />
    </div>

    <if(action)>
        <ebay-icon-button
            class="find-installer__chevron"
            disabled=state.disableButton
            aria-label=actionAccessibilityText
            on-click('emit', 'get-installers', action)>
            <ebay-arrow-right-24-icon/>
            <!-- Debug :- SVG not loading through the ebay UI-->
            <div hidden aria-hidden="true">
                <svg viewbox="0 0 20 18" id="icon-arrow-right-24">
                    <path d="m21.71 11.29-8-8a1.004 1.004 0 0 0-1.42 1.42l6.3 6.29H3a1 1 0 0 0 0 2h15.59l-6.3 6.29a1.001 1.001 0 0 0 0 1.42 1.001 1.001 0 0 0 1.42 0l8-8a1.001 1.001 0 0 0 0-1.42Z"></path>
                </svg>
            </div>
        </ebay-icon-button>
    </if>

    <div hidden aria-hidden="true">
        <svg>
            <symbol#icon-radio-checked-18 viewBox='0 0 18 18'>
                <path d='M9 0a9 9 0 110 18A9 9 0 019 0zm0 1a8 8 0 100 16A8 8 0 009 1zm0 3a5 5 0 110 10A5 5 0 019 4z'/>
            </symbol>
            <symbol#icon-radio-unchecked-18 viewBox='0 0 18 18'>
                <path d='M9 18A9 9 0 119 0a9 9 0 010 18zM9 1a8 8 0 100 16A8 8 0 009 1z'/>
            </symbol>
        </svg>
    </div>
</div>
