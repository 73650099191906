$ const { get } = require('@ebay/retriever');

$ const {
    model: {
        heading,
        subHeading,
        expandLabel,
        entries,
        label
    } = {}
} = input;

<div class="whats-included">
    <if(heading)>
        <span class="whats-included__title" tabindex="-1">
            <ux-textualdisplay model=heading />
            
            <if(subHeading)>
                <span class="whats-included__sub-heading">
                    <ux-textualdisplay model=subHeading />
                </span>
            </if>
        </span>
    </if>

    <ebay-details class="whats-included__details" text=get(expandLabel, 'textSpans.0.text', '') size="regular" open=false as="p">
        <ul>
            <for|list| of=(entries || [])>
                <li>
                    <ux-textualdisplay model=(list && list.placeHolder) />
                </li>
            </for>
        </ul>
        
        <if(label)>
            <div class="whats-included__see_more" on-click('emit', 'see-more-click', label && label.action)>
                <ux-textualdisplay model=(label)/>
            </div>
        </if>
    </ebay-details>
    <!-- SVG not loading through the ebay UI bcoz of shadow dom implemenation -->
    <div hidden aria-hidden="true">
        <svg viewBox="0 0 16 16" id="icon-chevron-down-12">
            <path d="M12.194 6.306a.625.625 0 0 0-.881 0L8 9.62 4.694 6.306a.628.628 0 0 0-.888.888l3.75 3.75a.625.625 0 0 0 .882 0l3.75-3.75a.625.625 0 0 0 .006-.888Z"></path>
        </svg>
        <svg viewBox="0 0 16 16" id="icon-chevron-down-16">
            <path d="M8.707 12.707a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 1.414-1.414L8 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6Z"/>
        </svg>
    </div>
            
</div>
