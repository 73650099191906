$ const { get } = require('@ebay/retriever');

$ {
    const {
      model: {
        VAS_ERROR: layerError,
        VAS_STATUS_MESSAGE: defaultError,
        VAS_DATA,
        VAS_HEADER_MODULE: {
            title: headerTitle,
            closeIcon,
            backIcon
        } = {},
        VAS_CTA,
        VAS_IMAGE: {
            image: {
                title: imageTitle,
                URL: imageUrl
            } = {}
        } = {},
        VAS_SUMMARY: summary,
        VAS_PERSONAL_DATA: {
            title: personalDataTitle,
            subTitle: personalDataSubtitle,
            dataItems: personalDataCards
        } = {},
        VAS_FOOTER: {
            infoCard: {
                icon: footerIcon,
                details: footerText
            } = {},
            details: footerTexts
        } = {}
      } = {},
      global: {
         isMobile
      } = {},
      showError,
      hideBackIcon
   } = input;
}
<header 
    class-name="parts-header"
    model=closeIcon 
    on-close('emit', 'close')>
    <@headerLeft>
        <if(backIcon && !hideBackIcon)>
            <ebay-icon-button on-click('emit', 'back', 'summary', backIcon)>
                <ebay-chevron-left-24-icon />
            </ebay-icon-button>
        </if>
    </@headerLeft>
    <@title>
        <if(headerTitle)>
            <h2 class="header-title">
                <ux-textualdisplay model=headerTitle />
            </h2>
        </if>
    </@title>
</header>
<if(showError)>
    <error-screen model=defaultError />
</if>
<else-if(state.loading)>
    <div class="parts-summary-loading">
        <screen-loading top={count:1, type:'textbox'} left={count:isMobile? 4 : 4, type:'composite'} right={count:isMobile? 0 : 3, type:'image'} footer={count:1, type:'textbox'}/>
    </div>
</else-if>
<else>
    <div class=['parts-summary', (layerError ? 'error' : '')]>
        <if(layerError)>
            <notice type="page" model=layerError isMobile=isMobile on-notice-cta-click('emit', 'notice-cta-click') />
        </if>
        <if(imageUrl)>
            <div class="parts-summary__image">
                <img src=imageUrl alt=imageTitle />
            </div>
        </if>
        <div class="parts-summary__content">
            <div class="parts-summary__left">            
                <div class="parts-summary__cards">
                    <card-tile model=summary variant="vertical" on-click-card('handleCard') />
                </div>
            </div>
            <div class="parts-summary__right">
                <if(personalDataTitle)>
                    <p class="parts-summary__title">
                        <ux-textualdisplay model=personalDataTitle/>
                    </p>
                </if>
                <if(personalDataCards)>
                    <div class="parts-summary__fields">
                        <for|card| of=personalDataCards>
                            $ {
                                const {
                                    heading,
                                    entries,
                                    secondaryLabel,
                                    validations
                                } = card || {};
                            }
                            <div class="parts-summary__feild-label">
                                <ux-textualdisplay model=heading />
                            </div>
                            <for|feild,idx| of=entries>
                                <div class="parts-summary__feild-input">
                                    $ const error = state[feild.paramKey+'_result'];
                                    $ const validation = validations[idx];
                                    $ const showError = state[feild.paramKey+'_showError'];
                                    <ebay-textbox
                                        on-input-change('handleInputChange', feild.paramKey, validation)
                                        fluid
                                        value=state[feild.paramKey]
                                        name=feild.paramKey
                                        autocomplete="off"
                                        aria-label=feild.accessibilityText
                                        invalid=(showError && !error)
                                        placeholder=get(feild, 'placeHolder.textSpans.0.text')>
                                    </ebay-textbox>
                                    <if(showError)>
                                        <p hidden=(error) class="parts-summary-feild__error-message">
                                            <ux-textualdisplay model=get(card, 'validations.0.message.title', {}) />
                                        </p>
                                    </if>
                                    <if(secondaryLabel)>
                                        <p>
                                            <ux-textualdisplay model=secondaryLabel />
                                        </p>
                                    </if>                               
                                </div>
                            </for>                     
                        </for>
                    </div>
                </if>
                <if(personalDataSubtitle)>
                    <p class="parts-summary__subtitle">
                        <ux-textualdisplay model=personalDataSubtitle/>
                    </p>
                </if>
                <if(!isMobile)>
                    <div class="parts-summary__infocard">
                        <ebay-lightbulb-24-icon
                            class="icon icon--lightbulb-24"
                            focusable="false"
                            no-skin-classes/>
                        <footer-text model=footerText />
                    </div>
                    <div class="parts-summary__footerTexts">
                        <footer-text model=footerTexts on-provider-clicked("onProviderClicked")/>
                    </div>
                </if>
                <else>
                    <div class="parts-summary__infocard">
                        <ebay-lightbulb-24-icon
                            class="icon icon--lightbulb-24"
                            focusable="false"
                            no-skin-classes/>
                        <footer-text model=footerText />
                    </div>
                </else>      
            </div>
        </div>
        <div class="parts-summary__footer sticky_footer">    
            <if(isMobile)>    
                <footer-text model=footerTexts on-provider-clicked("onProviderClicked")/>
            </if>
            <ctas
                fluid=isMobile
                model=VAS_CTA
                on-click('handleClick')
                is-busy=false
                disable-primary=state.disableCta />
        </div>     
    </div>
</else>