$ const { get } = require('@ebay/retriever');
$ const personalDataModel = get(input, 'model', {});
$ const dataItems = get(personalDataModel, 'dataItems', []);

<if(personalDataModel.title)>

  <h3 class='tires-personaldata__title'>
    <ux-textualdisplay model=personalDataModel.title/>
  </h3>
  </if>
<for|dataItemGroup, idx| of=dataItems>
    $ const validations = dataItemGroup.validations;
    <div class='tires-personaldata__field-input'>
      <if(dataItemGroup.uxComponentHint === 'DROP_DOWN' && dataItemGroup.entries.length)>
        $ const firstEntry = dataItemGroup.entries[0];
        $ const error = state[dataItemGroup.entries[0].paramKey + '_result'];
        $ const showError = state[dataItemGroup.entries[0].paramKey + '_showError'];

        <ebay-select
          on-change('handleDropdownInputChange', firstEntry.paramKey, validations)
          name=firstEntry.paramKey
          class={
            'tires-personaldata__select': true,
            'select-placeholder': !state.validationResult[`${firstEntry.paramKey}_result`]}
          data-testid='tires-personaldata-dropdown'
        >
          <@option value=(dataItemGroup.label.textSpans[0].text) text=(dataItemGroup.label.textSpans[0].text)/>
          <for|option| of=dataItemGroup.entries || []>
            <@option
              value=option.paramValue
              text=get(option, 'label.textSpans[0].text', '')
              selected=option.paramValue === state[firstEntry.paramKey]
            />
          </for>
        </ebay-select>
        <if(showError)>
            <p hidden=error class='tires-personaldata-field__error-message'>
              <ux-textualdisplay model=get(dataItemGroup, 'validations.0.message.title', {})/>
            </p>
          </if>
      </if>
      <else-if(dataItemGroup.uxComponentHint === 'TextualEntry')>
        <for|entry, entryIdx| of=dataItemGroup.entries>
          $ const error = state[entry.paramKey + '_result'];
          $ const showError = state[entry.paramKey + '_showError'];
          <ebay-textbox
            on-input-change('handleInputChange', entry.paramKey, validations)
            fluid
            value=state[entry.paramKey]
            name=entry.paramKey
            autocomplete='off'
            aria-label=dataItemGroup.accessibilityText
            class='tires-personaldata__textbox'
            invalid=showError && !error
            placeholder=get(entry, 'label.textSpans.0.text')
          />
          <if(showError)>
            <p hidden=error class='tires-personaldata-field__error-message'>
              <ux-textualdisplay model=get(dataItemGroup, 'validations.0.message.title', {})/>
            </p>
          </if>
        </for>
      </else-if>
    </div>
  </for>
<div hidden aria-hidden="true">
  <svg viewBox="0 0 12 12" id="icon-chevron-down-12">
    <!-- <path d="M12.194 6.306a.625.625 0 0 0-.881 0L8 9.62 4.694 6.306a.628.628 0 0 0-.888.888l3.75 3.75a.625.625 0 0 0 .882 0l3.75-3.75a.625.625 0 0 0 .006-.888Z"></path> -->
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.808 4.188a.625.625 0 0 1 .884 0L6 7.495l3.308-3.307a.625.625 0 1 1 .884.885l-3.75 3.749a.625.625 0 0 1-.884 0l-3.75-3.749a.626.626 0 0 1 0-.885Z"></path>
  </svg>
  <svg viewBox="0 0 16 16" id="icon-chevron-down-16">
    <path d="M8.707 12.707a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 1.414-1.414L8 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6Z"/>
  </svg>
</div>
