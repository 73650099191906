$ const { get } = require('@ebay/retriever');

$ {
   const {
      model: {
         VAS_STATUS_MESSAGE: defaultError,
         VAS_ITEM_CARD_MINIMAL: {
            card: {
                image: topImage,
                mapCenterIcon,
                title,
                __vas: {
                    details: serviceOperationDetails,
                    detailSection: serviceCenterDetails,
                    label,
                    secondaryLabels,
                    reviewSummary: {
                        starRating,
                        feature
                    } = {},
                    vasValues: mapData
                } = {}
            } = {}
         } =  {},         
         VAS_CTA,
         VAS_HELP,
         VAS_FAQ,
         VAS_PROVIDER,
         VAS_APPOINTMENT_SCHEDULE: {
            calendar = {},
            time = {}
         } = {},
        VAS_HEADER_MODULE: {
            title: headerTitle,
            closeIcon,
            backIcon
        } = {},
      } = {},
      global: {
         language,
         country,
         isMobile
      } = {},
      mapConfig = {},
      showError,
      hideBackIcon
   } = input;

   const localeString = language && country ? `${language}-${country}` : 'en-US';
   
    const {
        selectedDate,
        enableSlots,
        selectedSlot,
        isBusy,
        mapLoaded,
        loading
    } = state;
};

<header 
    class-name="parts-header"
    model=closeIcon 
    on-close('emit', 'close')>
    <@headerLeft>
        <if(backIcon && !hideBackIcon)>
            <ebay-icon-button on-click('emit', 'back', 'appointment', backIcon)>
                <ebay-chevron-left-24-icon />
            </ebay-icon-button>
        </if>
    </@headerLeft>
    <@title>
        <if(headerTitle)>
            <h2 class="header-title">
                <ux-textualdisplay model=headerTitle />
            </h2>
        </if>
    </@title>
</header>
<if(showError)>
    <error-screen model=defaultError />
</if>
<else>
    <div class="parts-scheduling">
        <if(loading)>
            <screen-loading top={count:1, type:'textbox'} left={count:isMobile? 4 : 4, type:'composite'} right={count:isMobile? 0 : 3, type:'image'} footer={count:1, type:'textbox'}/>
        </if>
        <else>
            <div class="parts-scheduling__content">
                <div class="parts-scheduling__left">
                    <if(topImage)>
                        <img src=`${topImage.URL}` alt=`${topImage.title}` class="parts-scheduling__image" />
                    </if>
                    <div class="parts-scheduling__installer-card">
                        <installer-card
                            model={
                                label,
                                secondaryLabels,
                                starRating,
                                feature
                            } 
                            variant="no-radio" />  
                    </div>
                    <if(isMobile)>
                        <ebay-details class="parts-scheduling__operation-section" text=get(serviceCenterDetails, 'expandControls.viewMore.textSpans.0.text', '') size="regular" open=false as="p">
                            <div class="parts-scheduling__operation">
                                <service-operation model=serviceOperationDetails />
                            </div>
                        </ebay-details>
                    </if>
                    <if(calendar.paramKey)>                           
                        <div class="parts-scheduling__calendar">
                            <ebay-calendar
                                ...calendar
                                on-select("onDateSelected")
                                on-month-change("emit", "month")
                                on-focus("emit", "focus")
                                selected=selectedDate
                                locale=localeString
                            />
                        </div>
                    </if>
                    <scheduling-toggle 
                        model=time 
                        enableSlots=enableSlots 
                        selectedSlot=selectedSlot 
                        on-time-slot-click("timeSlot") />
                </div>
                <div class="parts-scheduling__right">
                    <if(title)>
                        <h4 class="parts-scheduling__right-title">
                            <ux-textualdisplay model=title />
                        </h4>
                    </if>
                    <if(!isMobile)>
                        <div class="parts-scheduling__map-operation">
                            <div class="parts-scheduling__map-lists" no-update-body-if(mapLoaded)>
                                <if(mapConfig.enabled && mapData)>
                                    <map
                                        api-key=mapConfig.key
                                        center=mapCenterIcon
                                        country=country
                                        language=language
                                        model=[{
                                            paramValue: mapData.paramValue || 0,
                                            vasValues: mapData,
                                            secondaryLabels,
                                            starRating
                                        }]
                                        selected-installer= {
                                            ...mapData
                                        }
                                        variant="arrow-tooltip"
                                        show-info="button"
                                    />
                                </if>
                            </div>
                            <div class="parts-scheduling__operation">
                                <service-operation model=serviceOperationDetails />
                            </div>
                        </div>
                    </if> 

                    <scheduling-help model=VAS_HELP />
                    <if(VAS_PROVIDER)>
                        <scheduling-provider model=VAS_PROVIDER on-provider-clicked("onProviderClicked")/>
                    </if>
                    <scheduling-faq is-mobile=isMobile model=VAS_FAQ />
                </div>
            </div>
            <div class="parts-scheduling__footer sticky_footer">
                <ctas
                    fluid=isMobile
                    model=VAS_CTA
                    on-click('handleClick')
                    disable-primary=!(selectedDate  && selectedDate.length > 0 && selectedSlot)
                    processing-action-name=get(VAS_CTA, 'sections.0.dataItems.0.action.name')
                    is-busy=isBusy />
            </div>
        </else>
    </div>
</else>

