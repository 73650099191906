const {get} = require('@ebay/retriever');

module.exports = class {
    onCreate(input) {
        const model = input.model || {};
        const appointmentModule = get(model, 'VAS_APPOINTMENT_SCHEDULE', {});
        const calendarInput = get(appointmentModule, 'calendar', {});
        let defaultSelectedDate = null;
        let defaultSelectedDateArr = null;
        if (calendarInput && calendarInput.defaultSelectedDate) {
            defaultSelectedDate = calendarInput.defaultSelectedDate;
            defaultSelectedDateArr = [defaultSelectedDate]
        }
        this.isoDateStringMap = get(input, 'model.VAS_APPOINTMENT_SCHEDULE.calendar.additionalParamKeyValues', {});
        this.slotEntries = get(input, 'model.VAS_APPOINTMENT_SCHEDULE.time.entries', []);
        const slotDetails = this.getSlotDetails(defaultSelectedDate, true) || {};
        this.state = {
            selectedSlot: slotDetails.selectedSlotParamValue || '',
            selectedDate: defaultSelectedDateArr,
            vasSelection: slotDetails.vasSelection,
            enableSlots: slotDetails.enabledSlots || []
        }
    }

    onMount() {
        this.broadcastSelectedAppointment(this.state.selectedDate, this.state.selectedSlot, this.state.vasSelection);
    }

    broadcastSelectedAppointment(selectedDate, selectedSlot, vasSelection) {
        if (selectedDate && selectedDate.length > 0 && selectedSlot){
            let isoSelectedDateStr = this.isoDateStringMap[selectedDate[0]]; // iso date string from VAS_MP
            if (!isoSelectedDateStr) { // Fallback FE convert the date to ISO date string
                isoSelectedDateStr = selectedDate[0];
            }            
            this.emit('vas-appointment-change', {selectedSlot: selectedSlot, selectedDate: isoSelectedDateStr, vasSelection: vasSelection })
        } else {
            this.emit('vas-appointment-change', {selectedSlot: '', selectedDate: '', vasSelection: vasSelection })
        }
    }

    /**
     * Triggered by date dropdown selection
     * @param evtData 
     */
    onDateDropdownSelected(evtData) {
        if (evtData && evtData.selected && evtData.selected.length > 0) {
            const slotDetails = this.getSlotDetails(evtData.selected[0], false) || {};
            this.setState({
                selectedDate: [evtData.selected[0]],
                enableSlots: slotDetails.enabledSlots || [],
                selectedSlot: slotDetails.selectedSlotParamValue || '',
                vasSelection: slotDetails.vasSelection
            });
            
            this.broadcastSelectedAppointment(this.state.selectedDate, this.state.selectedSlot, this.state.vasSelection);  // update CTAs
        }
    }

    /**
     * Trigger by onRadioSelected
     * @param evtData
     */
    handleTimeSlot(evtData) {
        if (evtData && evtData.selectedSlot) {
            this.setState({
                selectedSlot: evtData.selectedSlot,
                vasSelection: evtData.vasSelection
            });
            this.broadcastSelectedAppointment(this.state.selectedDate, this.state.selectedSlot, this.state.vasSelection);
        }
    }
    
    handleLicenseChange(evtData) {
        const mergedVasSelection = {...this.state.vasSelection, ...evtData.selectedParam}; 


        if (evtData && evtData.selectedParam) {
            this.setState({
                vasSelection: mergedVasSelection,
                disableCta: evtData.disableCta
            });

            this.emit('vas-personal-data-change', { vasSelectionParam: mergedVasSelection, disableCta: evtData.disableCta})
        }
    }

    getSlotDetails(selectedDate, onload) {
        const _enableSlots = [];
        let selectedSlotParamValue = '';
        let vasSelection = null;
        let valueUpdated = false;
        this.slotEntries.forEach((entry) => {
            const dates = get(entry, "filter.values", []);
            if (dates.includes(selectedDate)) {
                _enableSlots.push(get(entry, "paramValue", ''))
                const isUpdateRequired = onload ? entry.selected : !valueUpdated;
                if (isUpdateRequired ) {
                    selectedSlotParamValue = entry.paramValue;
                    if(entry.additionalParamKeyValues) {
                        vasSelection = entry.additionalParamKeyValues;
                    }
                    valueUpdated = true;
                }
            }
        });
        return {
            enabledSlots : _enableSlots,
            selectedSlotParamValue: selectedSlotParamValue,
            vasSelection: vasSelection
        };
    }
};
