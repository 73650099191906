/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
const pubsub = require('raptor-pubsub');
const { get } = require('@ebay/retriever');
const { appendMissingParams } = require('../../common/utils');

const ERR_GENERAL_TRACKING = [
  {
    eventFamily: 'ITM',
    eventAction: 'ACTN',
    actionKind: 'VIEW',
    operationId: '2349624',
    flushImmediately: false,
    eventProperty: {
      moduledtl: '144737',
      sid: 'p2349624.m144737.l147910',
    },
  },
];

// no add item selected when user land on warranty interstitial overlay
module.exports = class {
  onCreate(input) {
    const vaultType = get(input, 'model.modules.VAS_META.schemaVersion.VAULT', '');

    const srt = get(input, 'model.srt', '');
    const genericErrorTxt = get(input, 'model.modules.STATUS_MESSAGE.message.title.textSpans[0].text', '');
    const entries = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.vasForm', []);
    const cta = get(input, 'model.modules.VAS_INTERSTITIAL_CTA.sections[0].dataItems', []);
    const ctaSections = get(input, 'model.modules.VAS_INTERSTITIAL_CTA.sections', []);

    this.state = {
      qualifyingVariant: get(input, 'qualifyingVariant', ''),
      vaultType: vaultType,
      srt: srt,
      channelId: input.channelId,
      isBusy: false,
      btnClickedIdx: -1,
      secondaryBtnClickedIdx: -1,
      primaryBtnClickedIdx: -1,
      config: input.config,
      error: '',
      genericErrorTxt: genericErrorTxt,
      showVaultEnrollmentiframe: false,
      iframeAction: {},
      addonsSelectionMap: '',
      highAspMsg: false,
      itemsToShow: this.getItemsToShow(entries, input.qualifyingVariant),
      cta: cta,
      ctaSections: ctaSections,
    };
    if (vaultType === 'VAULT_NUDGE_REDESIGN') {
      this.getDefaultRadioBtn(entries);
    }
    this.proxyCloseInterstitial = this.closeInterstitial.bind(this);
  }

  onMount() {
    const trackingList = get(this.input, 'model.modules.VAS_INTERSTITIAL_MODULE.meta.trackingList', []).concat(
      get(this.input, 'model.modules.VAS_INTERSTITIAL_CTA.meta.trackingList', [])
    );
    if (trackingList.length > 0) {
      pubsub.channel(this.input.channelId).emit('tracking', trackingList);
    }

    window.addEventListener('pageshow', this.proxyCloseInterstitial);
  }

  onDestroy() {
    window.removeEventListener('pageshow', this.proxyCloseInterstitial);
  }

  onCloseButtonClick(action, event) {
    pubsub.channel(this.state.channelId).emit('VAS_CLOSE_INTERSTITIAL');
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
  }

  onRadioSelected(addonEntry) {
    if (addonEntry) {
      if (addonEntry.action && addonEntry.action.trackingList) {
        pubsub.channel(this.state.channelId).emit('tracking', addonEntry.action.trackingList);
      }
      this.setState('addonsSelectionMap', addonEntry.paramValue);
      this.setStateDirty('addonsSelectionMap');
      if (addonEntry.paramValue === '-1') {
        this.setState('highAspMsg', true);
        this.setStateDirty('highAspMsg');
      } else {
        this.setState('highAspMsg', false);
        this.setStateDirty('highAspMsg');
      }
    }
  }

  closeInterstitial(event) {
    const historyTraversal =
      event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
    if (historyTraversal) {
      pubsub.channel(this.state.channelId).emit('VAS_CLOSE_INTERSTITIAL');
    }
  }

  onCTAButtonClick(action, btnIdx, event) {
    let selectedRadioValue = '';
    let selectedServices = {};
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
    this.state.isBusy = true;
    if (this.state.vaultType === 'VAULT_NUDGE_REDESIGN') {
      if (action.params) {
        selectedRadioValue = this.state.addonsSelectionMap;
        action.params.value = selectedRadioValue;
        selectedServices = { 'VAULT': [selectedRadioValue] };
        action.params.selectedServices = JSON.stringify(selectedServices);
      }
      this.state.btnClickedIdx = btnIdx;
    } else if (this.state.vaultType === 'VAULT_ADOPTION') {
      const value = get(action, 'params.value', '');
      if (value) {
        selectedServices = { 'VAULT': [value] };
      } else {
        selectedServices = { 'VAULT': ['-1'] };
      }
      action.params.selectedServices = JSON.stringify(selectedServices);
      if (btnIdx === 0) {
        this.state.secondaryBtnClickedIdx = btnIdx;
      } else {
        this.state.primaryBtnClickedIdx = btnIdx;
      }
    }

    let actionURL = get(action, 'URL', '');
    const actionType = get(action, 'type', '');
    if (actionType === 'NAV') {
      actionURL = appendMissingParams(actionURL, action.params);
      window.location.assign(actionURL);
      return;
    } else if (actionType === 'OPERATION' && action.params && action.params.iframe === '1') {
      this.state.showVaultEnrollmentiframe = true;
      this.state.iframeAction = action;
      return;
    }

    // start ajax request
    let payload;
    try {
      payload = JSON.parse(JSON.stringify(action.params));
      if (payload) {
        payload.selectedServices = selectedServices;
        if (!payload.actionParams) {
          payload.actionParams = JSON.parse(JSON.stringify(action.params));
        }
        // payload.variationId = this.state.qualifyingVariant;
        // if (!Number(payload.variationId)) {
        //   delete payload.variationId;
        // }

        const quantityEle = document.querySelector('#qtyTextBox');
        if (quantityEle) {
          payload.quantity = quantityEle.value;
        }
      }

      // eslint-disable-next-line no-empty
    } catch (error) {}

    // build url path and params, nudge can be rendered by BIN or ATC. shopActionBinUrl/shopActionAtcUrl already has path and srt ex. /act/bin?srt=srt
    let reqUrl = '';
    if (this.state.config) {
      if (this.state.config.shopActionBinUrl) {
        reqUrl = this.state.config.shopActionBinUrl; // For backward compatibility, can be removed once https://github.corp.ebay.com/ViewItem/viewitem_raptor/pull/5841 is merged
      } else if (this.state.config.shopActionUrl) {
        reqUrl = this.state.config.shopActionUrl;
      }
    }

    if (this.state.config && this.state.config.urlParams) {
      const u = new URL(reqUrl, window.location.href);

      const params = this.state.config.urlParams;
      Object.keys(params).forEach((key) => {
        if (params.hasOwnProperty(key)) {
          u.searchParams.append(key, params[key]);
        }
      });

      // update srt with latest csrf token
      if (this.state.srt && this.state.srt !== u.searchParams.get('srt')) {
        u.searchParams.set('srt', this.state.srt);
      }
      reqUrl = u.href;
    }

    // clean error message
    this.state.error = '';

    $.ajax({
      type: 'POST',
      url: reqUrl,
      dataType: 'json',
      data: payload,
      crossDomain: true,
      xhrFields: {
        withCredentials: true,
      },
    })
      .done((data) => {
        // refresh srt (res will have a new srt)
        if (data.srt) {
          this.state.srt = data.srt;
        }

        // handle 200 response with error module
        let success = true;
        const errorMsg = get(data, 'modules.STATUS_MESSAGE.message.title.textSpans[0].text', '');
        if (errorMsg) {
          success = false;
          pubsub.channel(this.state.channelId).emit('tracking', ERR_GENERAL_TRACKING);

          // Unblock call on Vault CTA error
          if (payload && get(payload, 'unBlockingOnError', 'false') === 'true' &&  payload.rucancel ) {
            window.location.href = payload.rucancel;
            return;
          }
          this.state.error = errorMsg;
        }

        // Handle actions defined in data.meta.screenFlowDestination
        if (success && data.meta && data.meta.screenFlowDestination && data.meta.screenFlowDestination.params) {
          if (data.meta.screenFlowDestination.type === 'NAV' && data.meta.screenFlowDestination.URL) {
            const destinationUrl = appendMissingParams(
              data.meta.screenFlowDestination.URL,
              data.meta.screenFlowDestination.params
            );
            window.location.assign(destinationUrl);
            return;
          } else if (
            data.meta.screenFlowDestination.type === 'OPERATION' &&
            data.meta.screenFlowDestination.params.iframe === '1'
          ) {
            this.state.showVaultEnrollmentiframe = true;
            this.state.iframeAction = data.meta.screenFlowDestination;
            return;
          }
        }

        this.state.isBusy = false;
        this.state.btnClickedIdx = -1;
        this.state.secondaryBtnClickedIdx = -1;
        this.state.primaryBtnClickedIdx = -1;

        // notify vi_raptor/vas.tag about the perform action result
        pubsub.channel(this.state.channelId).emit('VAS_INTERSTITIAL_CTA', {
          action: action,
          success: success,
          response: data,
        });

        // close interstitial on success case
        // vas.tag should take over and trigger the next action
        if (success) {
          pubsub.channel(this.state.channelId).emit('VAS_CLOSE_INTERSTITIAL');
        }
      })
      .fail((data) => {
        pubsub.channel(this.state.channelId).emit('tracking', ERR_GENERAL_TRACKING);

        // Unblock call on Vault CTA error
        if (payload && get(payload, 'unBlockingOnError', 'false') === 'true' &&  payload.rucancel ) {
          window.location.href = payload.rucancel;
          return;
        }

        this.state.isBusy = false;
        this.state.btnClickedIdx = -1;
        this.state.secondaryBtnClickedIdx = -1;
        this.state.primaryBtnClickedIdx = -1;

        // in error case, res will not have any specific error message, here we shouw generic error messagee and let user try again
        this.state.error = this.state.genericErrorTxt;

        // notify vi_raptor/vas.tag about the perform action result
        pubsub.channel(this.state.channelId).emit('VAS_INTERSTITIAL_CTA', {
          action: action,
          success: false,
          response: data,
        });

        // won't close interstitial, but blocker user on interstitial in error case
        // setTimeout(() => {
        //   console.log('1.close interstitial. 2.vas.tag should take over and trigger the next action.');
        //   pubsub.channel(this.state.channelId).emit('VAS_CLOSE_INTERSTITIAL');
        // }, 200);
      });
  }

  onLabelClick(action, event) {
    event.preventDefault();
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }

    if (action.params && action.params.iframe === '1') {
      this.state.showVaultEnrollmentiframe = true;
      this.state.iframeAction = action;
    } else if (action.URL) {
      window.open(action.URL, '_blank');
    }
  }

  getItemsToShow(entries, qualifyingVariant) {
    // backend ensures that only one entry is there
    if (entries.length === 1) {
      return entries;
    }
    return entries.filter(
      (entry) =>
        !qualifyingVariant ||
        (!!qualifyingVariant && entry.filter && entry.filter.values && entry.filter.values.includes(qualifyingVariant))
    );
  }

  getDefaultRadioBtn(entries) {
    entries.forEach((entry) => {
      const radioEntries = entry.entries;
      radioEntries.forEach((radioEntry) => {
        if (radioEntry.defaultChoice) {
          this.setState('addonsSelectionMap', radioEntry.paramValue);
          this.setStateDirty('addonsSelectionMap');
        }
      });
    });
  }
};
